import { frontendURL } from '../../../../helper/URLHelper';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: [
    'ongoing_campaigns',
    'one_off',
    'whatsapp',
    'settings_campaigns_whatsapp_new_stepOne',
    'target_public_new_stepTwo',
    'provider_new_stepThree',
    'message_new_stepFour',
    'dashboard',
  ],
  menuItems: [
    {
      icon: 'globe-desktop',
      label: 'ONGOING',
      key: 'ongoingCampaigns',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
      toStateName: 'ongoing_campaigns',
    },
    {
      key: 'oneOffCampaigns',
      icon: 'comment-note',
      label: 'ONE_OFF',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
      toStateName: 'one_off',
    },
    {
      key: 'whatsappCampaigns',
      icon: 'whatsapp',
      label: 'WHATSAPP',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/whatsapp`),
      toStateName: 'whatsapp',
    },
  ],
});

export default campaigns;
